.rolodex {
  height: 2em;
  overflow: hidden;
}
.rolodex p {
  margin: 0;
  color: #2f3a53;
}
.rolodex ul {
  margin-block-start: 0px;
  margin-block-end: 0px;

  
}
.rolodex > p,
.rolodex ul,
.rolodex li {
  float: left;
  line-height: 2em;

}
.rolodex li:before {
  content: "\a0";
  float: left;
}
.rolodex li + li {
  clear: left;
}
.rolodex ul {
  list-style: none;
  padding: 0;
}
.rolodex .roll-one {

  animation: roll-up-one 16s infinite cubic-bezier(.75,0,.25,1);
    color: #cf3;
    z-index: 2;
}
.rolodex .roll-two {
  animation: roll-up-two 16s infinite cubic-bezier(.75,0,.25,1);
    color: #2f3a53;
}
.rolodex .roll-three {
  animation: roll-up-three 16s infinite cubic-bezier(.75,0,.25,1);
    color: #e75139;
}
@keyframes roll-up-one {
  0%,
  100% {
    transform: translateY(0);
  }
  20%,
  80% {
    transform: translateY(-2em);
  }
}
@keyframes roll-up-two {
  0%,
  20%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-2em);
  }
  60%,
  80% {
    transform: translateY(-4em);
  }
  100% {
    transform: translateY(-8em);
  }
}
@keyframes roll-up-three {
  0%,
  60% {
    transform: translateY(0);
  }
  80%,
  100% {
    transform: translateY(-2em);
  }
}

.rolodex {
  min-width: 40%;
}
