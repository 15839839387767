
  @import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=IBM+Plex+Sans:wght@600&family=Poppins:wght@500&display=swap');


body {
  user-select: none;
}

.custom-container {
padding-left: 0 !important;
padding-right: 0 !important;
background-color: #fff;
/*font-family: 'Poppins', sans-serif;*/
font-family: 'Hind Siliguri', sans-serif;
}

.mx-auto {
  margin-left: 0 !important;
}


.no-scroll {
  overflow: hidden;
}

.nav-link.active {
  background-image: linear-gradient(120deg,#cf3,#223614);
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 90%;
  text-shadow: 0 0 1px #1b2e10;

}

@media (max-width: 768px) {
  .nav-link.active {
    background-position: 0 100%;
  }
}

.nav-link1.active {
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 0 90%;
  text-shadow: 0 0 1px #1b2e10;
}

.navbar::-webkit-scrollbar {
  display: none;
}


.mobile-menu {
  position: fixed;
  width: 100vw;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #ffffff;
  z-index: 1001;
}

.mobile-menu.is-visible {
  top: 9rem;
}

.mobile-menu.is-hidden {
  top: 6rem;
}

.mobile-menu::-webkit-scrollbar {
  width: 0em;
  background-color: transparent;
}

.mobile-menu::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.menu-animation {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.menu-animation.expanded {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  top: 8.5rem;
}

.grid-loader {
  top: 45%;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 6000,
}

#subsection-one {
  background-color:#ffffff;
}


#subsection-two {
  background-color: #ffffff;
}

#subsection-three {
  background-color:#ffffff;
}
#subsection-four {
  background-color:#ffffff;
}


:root {
  --light-bg-color: #e8ffa4;
  --light-text-color: #245e41;
  --dark-bg-color: #245e41;
  --dark-text-color: #e8ffa4;
}

[data-theme="light"] {
  --main-bg-color: var(--light-bg-color);
  --main-text-color: var(--light-text-color);
}

[data-theme="dark"] {
  --main-bg-color: var(--dark-bg-color);
  --main-text-color: var(--dark-text-color);
}

.form-control {
      width: 98% !important;
}

.no-border {
  border: none !important;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 1px !important;
}

.disabled {
  background-color: #fff !important;
  border: 1px solid #d6dce5 !important;
  color: #d6dce5!important;
  box-shadow: none !important;
}

.disabled:hover {
  border: 1px solid #d6dce5 !important;
  color: #d6dce5!important;
}

.disabled svg {
  color: #d6dce5!important;
}

.disabled:hover svg {
  color: #d6dce5!important;
}

.grecaptcha-badge { visibility: hidden; }

.userDashboardSection1:nth-child(even) {
  background-color: transparent;
    border-bottom: 0.5px solid #e3ff90;
}

.userDashboardSection1:nth-child(odd) {
  background-color: transparent;
  border-bottom: 0.5px solid #e3ff90;
  border-top: 0.5px solid #e3ff90;
}

.userDashboardSection2:nth-child(even) {
  background-color: transparent;
    border-bottom: 0.5px solid #81BC03;
}

.userDashboardSection2:nth-child(odd) {
  background-color: transparent;
  border-bottom: 0.5px solid #81BC03;
  border-top: 0.5px solid #81BC03;
}

.userDashboardSection3:nth-child(even) {
  background-color: transparent;
    border-bottom: 0.5px solid#f8ccc5;
}

.userDashboardSection3:nth-child(odd) {
  background-color: transparent;
  border-bottom: 0.5px solid #f8ccc5;
  border-top: 0.5px solid #f8ccc5;
}

.userDashboardSection4:nth-child(even) {
  background-color: transparent;
    border-bottom: 0.5px solid #98c9c3;
}

.userDashboardSection4:nth-child(odd) {
  background-color: transparent;
  border-bottom: 0.5px solid #98c9c3;
  border-top: 0.5px solid #98c9c3;
}

.userDashboardSection5:nth-child(even) {
  background-color: transparent;
    border-bottom: 0.5px solid #c0c6c2;
}

.userDashboardSection5:nth-child(odd) {
  background-color: transparent;
  border-bottom: 0.5px solid #c0c6c2;
  border-top: 0.5px solid #c0c6c2;
}

.insight-card {
  will-change: transform;
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
}

.insight-card .cover-photo {
  filter: brightness(85%);
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
}

.insight-card .insight-details {
  transform: translateY(59%);
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

@media (min-width: 992px) {
  .insight-card:hover .cover-photo {
    filter: brightness(50%);
  }

  .insight-card:hover .insight-details {
    transform: translateY(0%);
  }
}

.insight-card.in-view .cover-photo {
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.insight-card.in-view .insight-details {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
}

/* AdminSendEmail.css */

.email-table-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

}

th, td {
  padding: 5px;
  text-align: left;
}

th.sortable {
  background-color: #ced8d7;
  cursor: pointer;
}

td {
  border-bottom: 1px solid #7a9795;
}

th.sortable:hover {
  background-color: #7a9795;
  color: #fff;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-number {
  cursor: pointer;
  padding: 5px;
  margin: 0 2px;
  font-size: 16px;
  color:hsl(175, 11%, 79%);
}

.pagination .page-number.current {
  font-weight: bold;
  color: #7a9795;
}

.pagination select {
  padding: 5px;
  font-size: 14px;
}

.pagination label {
  font-size: 14px;
}
