*::after, *::before {
	 content: "";
	 position: absolute;
	 display: block;
}
 sectionA {
	 position: relative;
	 display: block;
	 flex: none;
     justify-content: center;
	 overflow: hidden;
	 width: 300px;
	 height: 240px;
	 background: #95a0af;
     border-radius: 12px;
     margin: 0;
     transform: scaleX(-1);
       @media (max-width: 350px) {
      transform: scale(0.8);
    }
}
.allAnimation {
    transform: scale(0.4);
    position: relative;
     left: -88px;
     top: -80px;
    
}
 .lampWrap {
	 height: 485px;
     width: 800px;
	 overflow: hidden;
	 position: relative;
     left: 0;
	 z-index: 2;
}
 .lampBase {
	 position: absolute;
	 bottom: 115px;
	 right: 210px;
	 height: 5px;
	 width: 66px;
	 background: #fff;
}
 .lampStand {
	 transform-origin: 615px bottom;
	 position: absolute;
     bottom: 115px;
	 right: 60px;
	 height: 485px;
	 width: 100%;
}
 .lampHead {
	 position: absolute;
	 transform-origin: 100% -140%;
	 right: 0px;
	 top: 50px;
	 width: 30px;
	 height: 30px;
	 transform: rotate(110deg);
}
 .lampshade {
	 position: absolute;
	 border-bottom: 73px solid #fff;
	 border-left: 9px solid transparent;
	 border-right: 9px solid transparent;
	 height: 0;
	 width: 59px;
	 right: -10px;
	 top: -60px;
	 z-index: 0;
}
 .lampshade::before, .lampshade::after {
	 content: "";
	 display: block;
	 position: absolute;
	 z-index: -1;
}
 .lampshade::after {
	 width: 26px;
	 height: 29px;
	 border-bottom-right-radius: 20px;
	 border-top-right-radius: 20px;
	 border-left: 7px solid rgb(147, 184, 38);
	 background: #cf3;
	 -webkit-transform: rotate(-90deg);
	 transform: rotate(-90deg);
	 left: 7px;
	 top: -27px;
}
 .moveSection {
	 position: absolute;
	 outline: 1px solid red;
	 display: block;
	 bottom: 0;
	 right: 100px;
}
 .bar {
	 position: absolute;
	 width: 7px;
	 background: #fff;
}
 .bar::after {
	 width: 25px;
	 height: 25px;
	 background: #95a0af;
	 border-radius: 50%;
}
 .bar.bar1 {
	 height: 110px;
	 right: 180px;
	 bottom: 5px;
	 transform-origin: center bottom;
}
 .bar.bar1::after {
	 transform: translate(-9px, -20px);
	 border: 5px solid #7d7d7d;
	 z-index: 1;
}
 .bar.bar2 {
	 transform: rotate(45deg);
	 height: 110px;
	 right: 0;
	 bottom: 120px;
	 transform-origin: center bottom;
}
 .bar.bar2::after {
	 top: 0;
	 border: 5px solid #223614;
	 transform: translate(-10px, -17px);
}
 .bar.bar3 {
	 height: 100px;
}
 .bar.bar3::after {
	 border: 5px solid #cf3;
	 transform: translate(-9px, -23px);
}
 .bar.bar4 {
	 transform: rotate(0deg);
	 height: 50px;
	 right: 0px;
	 top: -70px;
	 transform-origin: center bottom;
	 box-shadow: inset -1px 17px 0px 0px #b1afaf;
}
 .bar.bar4::after {
	 display: none;
}
 .bar.bar4::before {
	 position: absolute;
	 width: 60px;
	 height: 94px;
	 background: #95a0af;
	 left: -60px;
	 top: -34px;
	 z-index: 0;
}
 .rotator {
	 transform: rotate(-100deg);
	 position: relative;
	 right: 0px;
	 top: -115px;
	 height: 110px;
	 transform-origin: center bottom;
}
 .cord1 {
	 position: absolute;
	 background: #95a0af;
	 width: 40px;
	 height: 90px;
	 border: 5px solid #7d7d7d;
	 border-radius: 20px;
	 z-index: -1;
	 top: -12px;
	 left: -15px;
	 transform: rotate(-26deg);
}
 .cord1::after {
	 width: 40px;
	 height: 120px;
	 background: #95a0af;
	 left: 5px;
	 right: 20px;
	 position: absolute;
	 transform: rotate(30deg);
}
 .cord2 {
	 border: 5px solid #7d7d7d;
	 border-radius: 50%;
	 width: 72px;
	 height: 110px;
	 position: absolute;
	 left: -24px;
	 top: -35px;
	 z-index: -1;
	 transform: rotate(110deg);
}
 .bulb {
	 width: 40px;
	 height: 40px;
	 background: #cff;
	 left: -10px;
	 top: -12px;
	 position: absolute;
	 z-index: -1;
	 transform: rotate(-200deg);
	 border-radius: 50%;
}
 .bulb::after {
	 width: 36px;
	 height: 20px;
	 background: rgb(133, 161, 161);
	 left: 2px;
	 top: 9px;
	 border-radius: 10%;
	 transform: rotate(20deg);
}
 .lamp {
	 backface-visibility: hidden;
	 animation: curvy 9s ease-in-out infinite;
	 position: absolute;
	 left: -22px;
	 top: -9px;
	 transform: rotate(0deg);
	 transform-origin: 90% 85%;
}
 .lamp.st1 {
	 display: inline;
}
 .lamp.st2 {
	 fill: transparent;
}
 .path {
	 stroke: #fff;
	 stroke-width: 7px;
	 stroke-endcap: round;
}
 .lightBeam {
	 animation: flicker 10s linear infinite;
	 border-bottom: 700px solid #cff;
	 z-index: -1;
	 border-left: 250px solid transparent;
	 border-right: 250px solid transparent;
	 height: 0;
	 width: 60px;
	 box-sizing: content-box;
	 position: absolute;
	 right: -260px;
	 top: 13px;
	 transform: rotate(0deg);
}
 .jar {
	 position: absolute;
	 width: 245px;
	 height: 280px;
	 background: hsla(0, 0%, 100%, 0.123);
	 border: 4px solid #d6dce5;
	 z-index: 3;
	 bottom: 115px;
	 left: 100px;
	 border-radius: 200px 200px 15px 15px;
}
 .boxWrap {
	 position: absolute;
	 left: 105px;
	 bottom: 20px;
	 z-index: 3;
}
 .boxWrap .boxRight {
	 width: 80px;
	 height: 75px;
	 border-radius: 3px;
	 background: #cfd3d3;
	 position: absolute;
	 bottom: 110px;
	 left: 130px;
}
 .boxWrap .boxRight .ribbonVert {
	 position: absolute;
	 bottom: 0;
	 left: 55px;
	 width: 8px;
	 height: 75px;
	 background: #cf0;
}
 .boxWrap .boxRight .ribbonHorz {
	 position: absolute;
	 bottom: 50px;
	 left: 0;
	 width: 80px;
	 height: 8px;
	 background: #cf3;
}
 .boxWrap .boxRight .ribbonHorz::before {
	 position: absolute;
	 bottom: -50px;
	 left: 20px;
	 width: 8px;
	 height: 75px;
	 background: #2c2c2c;
     opacity: 20%;
}
 .boxWrap .boxRight .ribbonHorz::after {
	 position: absolute;
	 bottom: -5px;
	 left: 55px;
	 width: 8px;
	 height: 5px;
	 background: #223614;
     opacity: 25%;
}
 .boxWrap .boxCenter {
	 width: 120px;
	 height: 95px;
	 border-radius: 2px;
	 background: #fff;
	 position: absolute;
	 bottom: 110px;
	 left: 30px;
}
 .boxWrap .boxCenter::after {
	 width: 15px;
	 height: 95px;
	 background: #e75139;
	 left: 55px;
}
 .boxWrap .boxCenter .boxLid {
	 background: #fff;
	 border-radius: 2px;
	 width: 130px;
	 height: 12px;
	 position: absolute;
	 top: 0;
	 left: -5px;
}
 .boxWrap .boxCenter .boxLidShadow {
	 background: #a9a9a9;
	 width: 120px;
	 height: 5px;
	 position: absolute;
	 top: 12px;
	 left: 0;
}
 .boxWrap .boxTop {
	 width: 60px;
	 height: 60px;
	 border-radius: 2px;
	 background: #223614;
	 position: absolute;
	 bottom: 210px;
	 left: 80px;
}
 .boxWrap .boxTop::after {
	 width: 5px;
	 height: 60px;
	 background: #fff;
	 left: 30px;
}
 .boxWrap .boxTop .boxTopLid {
	 background: #223614;
	 border-radius: 2px;
	 width: 70px;
	 height: 12px;
	 position: absolute;
	 top: 0;
	 left: -5px;
}
 .boxWrap .boxTop .boxTopShadow {
	 background: #95a08d;
	 width: 60px;
	 height: 5px;
	 position: absolute;
	 top: 12px;
	 left: 0;
	 z-index: 1;
}
 .boxWrap .bow {
	 position: absolute;
	 bottom: 275px;
	 left: 90px;
	 width: 44px;
	 height: 20px;
	 border-radius: 2em;
	 background: #fff;
}
 .boxWrap .bow::after {
	 width: 25px;
	 height: 25px;
	 border-radius: 2em;
	 left: 10px;
	 top: -5px;
	 box-shadow: 0 -5px 6px #95a0af;
	 background: #fff;
}
 .footerMask {
	 height: 115px;
	 width: 760px;
	 background: #95a0af;
	 position: absolute;
	 bottom: 0px;
	 left: -10px;
     z-index: 2;
}
 .footerMask .deskTop {
	 background: #d6dce5;
	 float: right;
	 height: 10px;
	 width: 95%;
	 margin-top: 8px;
}
 @keyframes curvy {
	 0% {
		 transform: rotate(0deg);
	}
	 40% {
		 transform: rotate(0deg);
	}
	 50% {
		 transform: rotate(-3deg);
	}
	 60% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(0deg);
	}
}
 .lampStand {
	 animation: rotate1 8s 3s infinite ease-in-out;
}
 @keyframes rotate1 {
	 0% {
		 transform: rotate(0deg);
	}
	 5% {
		 transform: rotate(-15deg);
	}
	 75% {
		 transform: rotate(-15deg);
	}
	 80% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(0deg);
	}
}
 .bar2 {
	 animation: rotate2 8s 3s infinite ease-in-out;
}
 @keyframes rotate2 {
	 0% {
		 transform: rotate(45deg);
	}
	 5% {
		 transform: rotate(65deg);
	}
	 45% {
		 transform: rotate(65deg);
	}
	 50% {
		 transform: rotate(45deg);
	}
	 100% {
		 transform: rotate(45deg);
	}
}
 .rotator {
	 animation: rotate3 8s 3s infinite ease-in-out;
}
 @keyframes rotate3 {
	 0% {
		 transform: rotate(-100deg);
	}
	 5% {
		 transform: rotate(-130deg);
	}
	 45% {
		 transform: rotate(-130deg);
	}
	 50% {
		 transform: rotate(-100deg);
	}
	 100% {
		 transform: rotate(-100deg);
	}
}
 .bar4 {
	 animation: rotate4 8s 3s infinite ease-in-out;
}
 @keyframes rotate4 {
	 0% {
		 transform: rotate(0deg);
	}
	 5% {
		 transform: rotate(30deg);
	}
	 45% {
		 transform: rotate(30deg);
	}
	 50% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(0deg);
	}
}
 .lampHead, .cord2 {
	 animation: eyeballing 8s infinite ease-in-out;
}
 @keyframes eyeballing {
	 0% {
		 transform: rotate(122deg);
	}
	 20% {
		 transform: rotate(80deg);
	}
	 50% {
		 transform: rotate(100deg);
	}
	 90% {
		 transform: rotate(90deg);
	}
	 100% {
		 transform: rotate(122deg);
	}
}
 @keyframes flicker {
	 0% {
		 opacity: 0;
	}
	 6% {
		 opacity: 0.3;
	}
	 11% {
		 opacity: 0;
	}
	 22% {
		 opacity: 0.3;
	}
	 38% {
		 opacity: 0.3;
	}
	 39% {
		 opacity: 0.035;
	}
	 42% {
		 opacity: 0.3;
	}
	 53% {
		 opacity: 0.36;
	}
	 60% {
		 opacity: 0.25;
	}
	 60% {
		 opacity: 0;
	}
	 73% {
		 opacity: 0.25;
	}
	 75% {
		 opacity: 0.1;
	}
	 79% {
		 opacity: 0.25;
	}
	 100% {
		 opacity: 0.25;
	}
}
 .sparkle {
	 position: relative;
	 width: 0;
	 height: 0;
	 transform: scale(0.25);
}
 .sparkle::before, .sparkle::after {
	 content: "";
	 width: 0;
	 height: 0;
	 position: absolute;
	 border-left: 5px solid transparent;
	 border-right: 5px solid transparent;
}
 .sparkle::before {
	 border-top: 30px solid #fff;
}
 .sparkle::after {
	 transform: translatey(-100%);
	 border-bottom: 30px solid #fff;
}
 .sparkle span {
	 position: absolute;
	 transform: translate(5px, -5px);
}
 .sparkle span::before, .sparkle span::after {
	 content: "";
	 width: 0;
	 height: 0;
	 position: absolute;
	 border-top: 5px solid transparent;
	 border-bottom: 5px solid transparent;
}
 .sparkle span::before {
	 border-left: 30px solid #fff;
}
 .sparkle span::after {
	 transform: translatex(-100%);
	 border-right: 30px solid #fff;
}
 .sparkle1 {
	 animation: pulse1 8s ease-in-out infinite;
	 left: 21px;
	 top: 240px;
}
 .sparkle2 {
	 animation: pulse2 8s ease-in-out infinite;
	 left: 5px;
	 top: 190px;
}
 .sparkle3 {
	 animation: pulse6 8s ease-in-out infinite;
	 left: 8px;
	 top: 157px;
}
 .sparkle4 {
	 animation: pulse3 8s ease-in-out infinite;
	 left: 28px;
	 top: 135px;
}
 .sparkle5 {
	 animation: pulse7 8s ease-in-out infinite;
	 left: 78px;
	 top: 122px;
}
 .sparkle6 {
	 animation: pulse8 8s ease-in-out infinite;
	 left: 236px;
	 top: 247px;
}
 .sparkle7 {
	 animation: pulse10 8s ease-in-out infinite;
	 left: 184px;
	 top: 172px;
}
 .sparkle8 {
	 animation: pulse5 8s ease-in-out infinite;
	 left: 160px;
	 top: 144px;
}
 .sparkle9 {
	 animation: pulse9 8s ease-in-out infinite;
	 left: 175px;
	 top: 120px;
}
 .sparkle10 {
	 animation: pulse1 8s ease-in-out infinite;
	 left: 169px;
	 top: 50px;
}
 @keyframes pulse1 {
	 0% {
		 transform: scale(0);
	}
	 6% {
		 transform: scale(0);
	}
	 10% {
		 transform: scale(0.25);
	}
	 14% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse2 {
	 0% {
		 transform: scale(0);
	}
	 8% {
		 transform: scale(0);
	}
	 12% {
		 transform: scale(0.25);
	}
	 16% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse3 {
	 0% {
		 transform: scale(0);
	}
	 10% {
		 transform: scale(0);
	}
	 14% {
		 transform: scale(0.25);
	}
	 18% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse4 {
	 0% {
		 transform: scale(0);
	}
	 36% {
		 transform: scale(0);
	}
	 40% {
		 transform: scale(0.25);
	}
	 44% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse5 {
	 0% {
		 transform: scale(0);
	}
	 51% {
		 transform: scale(0);
	}
	 55% {
		 transform: scale(0.25);
	}
	 59% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse6 {
	 0% {
		 transform: scale(0);
	}
	 56% {
		 transform: scale(0);
	}
	 60% {
		 transform: scale(0.25);
	}
	 64% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse7 {
	 0% {
		 transform: scale(0);
	}
	 66% {
		 transform: scale(0);
	}
	 70% {
		 transform: scale(0.25);
	}
	 74% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse8 {
	 0% {
		 transform: scale(0);
	}
	 71% {
		 transform: scale(0);
	}
	 75% {
		 transform: scale(0.25);
	}
	 79% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse9 {
	 0% {
		 transform: scale(0);
	}
	 54% {
		 transform: scale(0);
	}
	 58% {
		 transform: scale(0.25);
	}
	 62% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}
 @keyframes pulse10 {
	 0% {
		 transform: scale(0);
	}
	 31% {
		 transform: scale(0);
	}
	 35% {
		 transform: scale(0.25);
	}
	 39% {
		 transform: scale(0);
	}
	 100% {
		 transform: scale(0);
	}
}

